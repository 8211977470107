<template>
  <b10-base>
    <b10-toolbar
      :title="title"
    >
      <v-btn
        slot="right"
        :disabled="!!cronometroFinicio"
        icon
        @click.stop="clickSubmit($refs.form)"
      >
        <v-icon>{{ $vuetify.icons.values.check }}</v-icon>
      </v-btn>
    </b10-toolbar>
    <b10-page-content
      :padding="3"
    >
      <v-form
        ref="form"
        lazy-validation
      >
        <tiempo-cronometro-iniciar-form
          :id="null"
          :geolocalizacion="geolocalizacion"
          @change-values="changeFormData"
        />
      </v-form>
      <tiempo-cronometro-iniciar-dialog
        :showing.sync="showingDialogs.yaIniciado"
        :mensaje="mensajeCronometroIniciado"
        @volver="ClickVolver"
        @detener="ClickDetener"
      />
    </b10-page-content>
  </b10-base>
</template>

<script>
import { formPageMixin } from '@/mixins/formPageMixin'
import { sync, get } from 'vuex-pathify'
import { captureGeolocationCheck, continuarConGeolocalizacion } from '@/utils/maps'
import { currentDateTime, toDateTime, currentDate, isSameDay, toDate } from '@/utils/date'
import { CRONOMETRO } from '@/utils/consts'
import TiempoCronometroIniciarForm from './components/TiempoCronometroIniciarForm'
import TiempoCronometroIniciarDialog from './components/TiempoCronometroIniciarDialog'


export default {
  components: {
    TiempoCronometroIniciarForm,
    TiempoCronometroIniciarDialog
  },
  mixins: [formPageMixin],
  data () {
    return {
      geolocalizacion: {},
      mensajeCronometroIniciado: '',
      showingDialogs: {
        yaIniciado: false,
      },
    }
  },
  computed: {
    usuarioCapturarGeolocalizacion: get('usuario/capturarGeolocalizacion'),
    ...sync(
      'cronometro',
      {
        cronometroFinicio: 'finicio',
        cronometroIdparte: 'idparte',
        cronometroLatitud: 'latitud',
        cronometroLongitud: 'longitud',
        cronometroPara: 'para',
        cronometroEsDesplazamiento: 'esDesplazamiento',
      }
    ),
  },
  async created () {
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    changeFormData (formData) {
      this.formData = formData
    },
    async loadPage () {
      this.title = 'Iniciar el cronómetro'
      if (this.cronometroFinicio) {
        this.showingDialogs.yaIniciado = !!this.cronometroFinicio
        const dateTimeCronometro = toDateTime(this.cronometroFinicio)
        const diaCronometro = toDate(dateTimeCronometro)
        let mensajeHoraCronometro = `a las ${this.$options.filters.shortTime(dateTimeCronometro)}`
        const hoy = currentDate()
        if (!isSameDay(diaCronometro, hoy)) {
          mensajeHoraCronometro = `el día ${this.$options.filters.shortDate(diaCronometro)} ${mensajeHoraCronometro}`
        }
        this.mensajeCronometroIniciado = `Tienes un cronómetro inciado ${mensajeHoraCronometro} ¿Qué desea hacer?`
        this.$alert.showSnackbarError('Ya hay un cronómetro iniciado')
      }
      this.geolocalizacion = await captureGeolocationCheck(this, this.usuarioCapturarGeolocalizacion)
    },
    async submitForm () {
      if (continuarConGeolocalizacion(this, this.usuarioCapturarGeolocalizacion, this.geolocalizacion)) {
        this.cronometroIdparte = null
        this.cronometroFinicio = currentDateTime()
        this.cronometroLatitud = this.formData.latitud
        this.cronometroLongitud = this.formData.longitud
        this.cronometroPara = CRONOMETRO.para.soloParaMi
        this.cronometroEsDesplazamiento = this.formData.es_desplazamiento
        this.$appRouter.go(-1)
      }
    },
    async ClickVolver() {
      this.$appRouter.push({ name: 'index' })
    },
    async ClickDetener() {
      this.$appRouter.push({
        name: 'tiempos__cronometro-detener',
      })
    },
  }
}
</script>
